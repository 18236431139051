import * as React from "react"
import { navigate } from "gatsby"
import fetch from "isomorphic-fetch"
import Variables from "../../constants/Variables"

function encode(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm: React.FunctionComponent = () => {
  const [state, setState] = React.useState({})

  const handleChange = (e: any): void => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e: any): void => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error: any) => alert(error))
  }

  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      action="/thanks/"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div>
        <label
          className="block text-gray-700 text-md font-bold mb-2"
          style={{ fontFamily: Variables.fontFamilyHeadline }}
        >
          Your name
        </label>
        <input
          className="bg-light appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 h-12"
          type="text"
          name="name"
          onChange={handleChange}
        />
      </div>
      <div>
        <label
          className="block text-gray-700 text-md font-bold mb-2"
          style={{ fontFamily: Variables.fontFamilyHeadline }}
        >
          Email
        </label>
        <input
          className="bg-light appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4 h-12"
          type="email"
          name="email"
          onChange={handleChange}
        />
      </div>
      <div>
        <label
          className="block text-gray-700 text-md font-bold mb-2"
          style={{ fontFamily: Variables.fontFamilyHeadline }}
        >
          Message
        </label>
        <textarea
          className="bg-light appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
          rows={8}
          name="message"
          onChange={handleChange}
        />
      </div>
      <br></br>
      <div className="text-center">
        <button
          className="bg-theme text-white py-2 px-10 rounded inline-flex items-center"
          style={{ fontFamily: Variables.fontFamilyHeadline }}
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default ContactForm
