import React from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout/Layout"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import ContactForm from "../components/forms/ContactForm"
import WideContainer from "../components/layout/WideContainer"
import SmallContainer from "../components/layout/SmallContainer"

interface Props {
  location: any
}

const ContactPage: React.FunctionComponent<PageProps> = ({
  location,
}: Props) => {
  return (
    <Layout>
      <MetaData
        title="Contact"
        description="Drop us a message and we will get to you as fast as possible."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <div className="bg-white">
        <WideContainer className="pt-4" center={true}>
          <h1 className="text-center mb-2">Contact</h1>
          <p className="mb-8 mt-0">Drop us a message</p>
        </WideContainer>
        <SmallContainer center={true} className="mb-6">
          <ContactForm />
        </SmallContainer>
      </div>
    </Layout>
  )
}

export default ContactPage
